import { Spacer, FlipValue } from 'constants/styling';
import styled, { css } from 'styled-components';
import { spacer, color } from 'theme';
import { Color } from 'types/Color';

export interface StyledIconProps {
  color?: Color;
  flip?: FlipValue;
  rotate?: 0 | 90 | 180 | 270;
  size?: Spacer;
  spin?: boolean;
}

export const StyledIcon = styled.div<StyledIconProps>`
  display: flex;
  // Prevent svg from becoming target of event
  pointer-events: none;

  .icon {
    width: ${({ size }) => spacer(size)};
    height: ${({ size }) => spacer(size)};
    min-width: ${({ size }) => spacer(size)};
    min-height: ${({ size }) => spacer(size)};

    fill: ${({ color: clr }) => color(clr)};
    color: ${({ color: clr }) => color(clr)};
    > * {
      fill: ${({ color: clr }) => color(clr)};
      color: ${({ color: clr }) => color(clr)};
    }

    transform: ${({ rotate }) => rotate && css`rotate(${rotate}deg)`};

    transform: ${({ flip }) => {
      if (flip === 'horizontal') {
        return 'scaleX(-1)';
      }

      if (flip === 'vertical') {
        return 'scaleY(-1);';
      }

      return null;
    }};
  }

  ${({ spin }) =>
    spin &&
    css`
      @-webkit-keyframes rotate {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes rotate {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      -webkit-animation-name: rotate;
      animation-name: rotate;

      -webkit-animation-duration: 1000ms;
      animation-duration: 1000ms;

      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;

      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    `};
`;
