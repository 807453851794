import { ICON_NAMES } from 'constants/icon';
import { Spacer } from 'constants/styling';
import { memo } from 'react';
import Icon from '../../Icon/Icon';
import { POSITIONS, StyledLoader, StyledLoaderProps } from './Loader.styled';

interface LoaderProps extends StyledLoaderProps {
  show?: boolean;
  size?: Spacer;
}

const Loader = ({
  className,
  color = 'primary-300',
  position = POSITIONS.FIXED,
  show,
  size = 400,
  ...marginProps
}: LoaderProps) => (
  <StyledLoader className={className} color={color} position={position} show={show} {...marginProps}>
    <Icon type="fa" name={ICON_NAMES.CIRCLE_NOTCH} size={size} spin />
  </StyledLoader>
);

export default memo(Loader);
