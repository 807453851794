import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { color, marginMapper, MarginProps, zIndex } from 'theme';
import { Color } from 'types/Color';

export const POSITIONS = {
  ABSOLUTE: 'absolute',
  FIXED: 'fixed',
  RELATIVE: 'relative',
} as const;
type StyledLoaderPosition = (typeof POSITIONS)[keyof typeof POSITIONS];

const positionStyling: {
  [key in StyledLoaderPosition]: ReturnType<typeof css>;
} = {
  [POSITIONS.FIXED]: css`
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  `,
  [POSITIONS.ABSOLUTE]: css`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
  [POSITIONS.RELATIVE]: css`
    background-color: transparent;
    position: relative;
  `,
};

export interface StyledLoaderProps extends MarginProps, HTMLAttributes<HTMLDivElement> {
  color?: Color;
  position?: StyledLoaderPosition;
  show?: boolean;
}

export const StyledLoader = styled.div<StyledLoaderProps>`
  ${marginMapper}

  display: ${({ show }) => (show ? 'flex' : 'none')};

  z-index: ${zIndex('modal-overwrite')};
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  color: ${color('primary-300')};

  svg {
    animation-duration: 0.5s;
  }

  ${({ color: colorValue }) => css`
    color: ${color(colorValue)};
  `}

  ${({ position }) => position && positionStyling[position]}
`;
