const { config, library } = require('@fortawesome/fontawesome-svg-core');
import {
  faApple,
  faFacebook,
  faFacebookF,
  faFacebookMessenger,
  faFigma,
  faGoogle,
  faInstagram,
  faLinkedin,
  faSlack,
  faTelegram,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowRight,
  faBan,
  faBars,
  faBicycle,
  faBoxOpen,
  faBug,
  faCalendarAlt,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClock,
  faCommentAltLines,
  faCreditCard,
  faDatabase,
  faDownload,
  faEdit,
  faEnvelope,
  faEquals,
  faEraser,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFileDownload,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilter,
  faFolderClosed,
  faGift,
  faHeart,
  faHome,
  faHomeLg,
  faInfoCircle,
  faList,
  faLocation,
  faLocationArrow,
  faMapMarkerAlt,
  faMegaphone,
  faMinus,
  faMinusCircle,
  faMobile,
  faMobileAlt,
  faNotEqual,
  faPhone,
  faPhoneRotary,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQrcode,
  faQuestionSquare,
  faQuoteRight,
  faReceipt,
  faRecycle,
  faRedo,
  faSearch,
  faShare,
  faShippingFast,
  faShoppingBag,
  faShoppingCart,
  faSignOut,
  faSlidersSimple,
  faSlidersV,
  faStar,
  faStarHalf,
  faStore,
  faTelescope,
  faTh,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTruck,
  faTruckLoading,
  faTvRetro,
  faUpload,
  faUser,
  faUserCircle,
  faWasher,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';

import {
  faBicycle as regularFaBicycle,
  faCheck as regularFaCheck,
  faEnvelope as regularFaEnvelope,
  faFileDownload as regularFaFileDownload,
  faFolderClosed as regularFaFolderClosed,
  faStore as regularFaStore,
  faWasher as regularFaWasher,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faStar as faStarSolid,
  faAngleDoubleRight as solidFaAngleDoubleRight,
  faAngleDown as solidFaAngleDown,
  faAngleLeft as solidFaAngleLeft,
  faAngleRight as solidFaAngleRight,
  faArrowRight as solidFaArrowRight,
  faBicycle as solidFaBicycle,
  faCircle as solidFaCirlce,
  faEnvelope as solidFaEnvelope,
  faFileDownload as solidFaFileDownload,
  faQuoteRight as solidFaQuoteRight,
  faSlidersV as solidFaSlidersV,
  faWasher as solidFaWasher,
} from '@fortawesome/pro-solid-svg-icons';

// Font Awesome
library.add(
  // Solids
  solidFaAngleDoubleRight,
  solidFaAngleLeft,
  solidFaAngleRight,
  solidFaArrowRight,
  solidFaCirlce,
  solidFaEnvelope,
  solidFaQuoteRight,
  solidFaSlidersV,
  solidFaAngleDown,
  solidFaFileDownload,
  solidFaWasher,
  solidFaBicycle,
  faStarSolid,

  // Light
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowRight,
  faArrowRight,
  faBan,
  faBars,
  faBoxOpen,
  faBug,
  faCalendarAlt,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClock,
  faCommentAltLines,
  faDatabase,
  faDatabase,
  faDownload,
  faEdit,
  faEnvelope,
  faEquals,
  faEraser,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilter,
  faGift,
  faHeart,
  faHome,
  faHomeLg,
  faInfoCircle,
  faList,
  faLocation,
  faLocationArrow,
  faMapMarkerAlt,
  faMegaphone,
  faMinus,
  faMinusCircle,
  faMobile,
  faMobileAlt,
  faNotEqual,
  faPhone,
  faFolderClosed,
  faPhoneRotary,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQrcode,
  faQuestionSquare,
  faQuoteRight,
  faReceipt,
  faReceipt,
  faRecycle,
  faRedo,
  faSearch,
  faShare,
  faShippingFast,
  faShoppingBag,
  faShoppingCart,
  faSignOut,
  faSlidersSimple,
  faSlidersV,
  faStar,
  faStarHalf,
  faStore,
  faTelescope,
  faTh,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTruck,
  faTruckLoading,
  faTvRetro,
  faUpload,
  faUser,
  faUserCircle,
  faWrench,
  faFileDownload,
  faCreditCard,
  faBicycle,
  faWasher,

  // Brands
  faApple,
  faFacebook,
  faFacebookF,
  faFacebookMessenger,
  faGoogle,
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faYoutube,
  faXTwitter,
  faFigma,
  faSlack,
  faTelegram,

  // Regular
  regularFaCheck,
  regularFaEnvelope,
  regularFaStore,
  regularFaFileDownload,
  regularFaWasher,
  regularFaBicycle,
  regularFaFolderClosed,
);

config.autoAddCss = false;
